/* eslint-disable react/require-default-props */
import React, { FC, useState, createRef, useRef } from 'react';

interface ShareCardProps {
  url: string;
  host: string;
  title: string;
  videoContentId: string;
  videoId: string;
  toggleShare(
    boolean
  ): void;
  twitter: string;
  isPlaying: boolean;
  isLiveBlog: boolean;
  liveBlogTracking(element: string, module: string, container: string): void;
  jwPlayerId: string;
  disableEmbed: boolean;
}

const {
  wp: {
    i18n: {
      __,
    },
  },
  nbc: {
    callLetters,
  },
} = window;

const domain = 'TELX' === callLetters ? 'cozi' : 'nbc';

const closeText = __('Close', domain);
const embedText = __('Press Ctrl-C to copy the code:', domain);
const linkText = __('Press Ctrl-C to copy link:', domain);
const selectText = __('Select Text', domain);
const copyLinkText = __('Copy Link', domain);
const embedLinkText = __('Embed', domain);

const ShareCard: FC<ShareCardProps> = ({
  url,
  host,
  title,
  videoContentId,
  videoId,
  toggleShare,
  twitter,
  isPlaying,
  isLiveBlog,
  liveBlogTracking,
  jwPlayerId,
  disableEmbed,
}) => {
  const {
    current: storedPlayState,
  } = useRef(isPlaying);

  const embedTextBox = createRef<HTMLTextAreaElement>();
  const linkTextBox = createRef<HTMLTextAreaElement>();
  const [showEmbed, setShowEmbed] = useState(false);
  const [showLink, setShowLink] = useState(false);

  const shareButtons = [
    {
      className: 'embed',
      callback: () => toggleEmbedLink('embed'),
      label: embedLinkText,
    },
    {
      className: 'link',
      callback: () => toggleEmbedLink('link'),
      label: copyLinkText,
    },
    {
      className: 'facebook',
      callback: () => socialShare('facebook'),
      label: 'Facebook',
    },
    {
      className: 'twitter',
      callback: () => socialShare('twitter'),
      label: 'X',
    },
  ];

  function toggleEmbedLink(option) {
    if ('embed' === option) {
      setShowEmbed(true);
      setShowLink(false);
      if (isLiveBlog) {
        liveBlogTracking('share embed', 'video click', jwPlayerId);
      }
    } else {
      setShowEmbed(false);
      setShowLink(true);
      if (isLiveBlog) {
        liveBlogTracking('share link copy', 'video click', jwPlayerId);
      }
    }
  }

  function selectFormText() {
    const activeEl = showEmbed ? embedTextBox.current : linkTextBox.current;
    if (activeEl) {
      setTimeout(() => {
        activeEl.focus();
        activeEl.setSelectionRange(0, activeEl.value.length);
      }, 0);
    }
  }

  function socialShare(platform) {
    const encodedTitle = encodeURIComponent(title);
    const encodedUrl = encodeURI(url);
    const shareUrl = 'twitter' === platform ?
      `https://twitter.com/share?url=${encodedUrl}&text=${encodedTitle}&via=${twitter}` :
      `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;

    window.open(shareUrl, '_blank', 'width=500,height=500');

    if (isLiveBlog) {
      liveBlogTracking(`share ${platform}`, 'video click', jwPlayerId);
    }
  }

  function checkURL(item) {
    return 'link' !== item.className || url;
  }

  const embedCode = `<script type="text/javascript" charset="UTF-8" 
    src="${window.location.protocol}//${host}/portableplayer/?CID=${videoContentId}&videoID=${videoId}&origin=${host}&fullWidth=y&autoplay=true"></script>`;

  return (
    <div className="player__share-card">
      <button
        type="button"
        onClick={() => {
          toggleShare(storedPlayState);
        }}
        onTouchEnd={() => {
          toggleShare(storedPlayState);
        }}
        className="share-card__button--close"
      >
        {closeText}
      </button>
      <div className="share-card__button-container">
        {
          shareButtons.filter(checkURL).map(({ callback, className, label }) => {
            if ('embed' === className && disableEmbed) {
              return '';
            }

            return (
              <button
                type="button"
                onClick={callback}
                onTouchEnd={callback}
                className={`share-card__button share-card__button--${className}`}
              >
                {label}
              </button>
            );
          })
        }
      </div>
      {(showEmbed || showLink) &&
        <>
          <div className="share-card__text-box">
            <form>
              <label htmlFor="text">{showEmbed ? embedText : linkText}</label>
              <textarea
                readOnly
                name="text"
                wrap="hard"
                value={showEmbed ? embedCode : url}
                ref={showEmbed ? embedTextBox : linkTextBox}
              />
            </form>
          </div>
          <button
            type="button"
            onClick={() => selectFormText()}
            onTouchEnd={() => selectFormText()}
            className="share-card__button--select"
          >
            {selectText}
          </button>
        </>
      }
    </div>
  );
};

export default ShareCard;
