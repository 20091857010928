import React, { FC } from 'react';
import { presetOptionsMap, textMap, effectMap, backgroundColorMap } from './subtitle-settings-utils';

interface PreviewSettingsProps {
  setPresetSetting: (
    string,
  ) => void;
}

const PresetSettings: FC<PreviewSettingsProps> = ({
  setPresetSetting,
}) => (
  <div className="preset-settings">
    {
      Object.keys(presetOptionsMap).map((key) => {
        const {
          borderSettings: {
            effect: borderEffect,
          },
          backgroundSettings: {
            color: backgroundColor,
            // opacity: backgroundOpacity,
          },
          textSettings: {
            color: textColor,
            // opacity: textOpacity,
            font: textFont,
          },
        } = presetOptionsMap[key];

        const {
          textColors,
          textFonts,
        } = textMap;

        const {
          returnStyle,
        } = effectMap[borderEffect];

        return 'default' !== key ? (
          <button
            type="button"
            className="preset-settings__button"
            style={{
              textShadow: returnStyle,
              backgroundColor: backgroundColorMap[backgroundColor],
              color: textColors[textColor],
              fontFamily: textFonts[textFont],
              fontSize: '17px',
            }}
            onClick={() => {
              setPresetSetting(presetOptionsMap[key]);
            }}
          >
            Aa
          </button>
        ) : '';
      })
    }
  </div>
);

export default PresetSettings;
