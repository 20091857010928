module.exports = new Set([
  'hlsjsProgressive',
  '__abSendDomainToFeeds',
  '_abZoomThumbnail',
  'advertising',
  'aboutlink',
  'abouttext',
  'aestoken',
  'allowFullscreen',
  'analytics',
  'androidhls',
  'aspectratio',
  'autoPause',
  'autostart',
  'base',
  'captions',
  'cast',
  'controls',
  'defaultBandwidthEstimate',
  'description',
  'displaydescription',
  'displayHeading',
  'displayPlaybackLabel',
  'displaytitle',
  'drm',
  'duration',
  'enableDefaultCaptions',
  'events',
  'file',
  'forceLocalizationDefaults',
  'fwassetid',
  'floating',
  'ga',
  'generateSEOMetadata',
  'height',
  'hlsjsConfig',
  'hlsjsdefault',
  'horizontalVolumeSlider',
  'image',
  'intl',
  'key',
  'listbar',
  'liveSyncDuration',
  'liveTimeout',
  'localization',
  'logo',
  'mediaid',
  'mute',
  'nextUpDisplay',
  'nextupoffset',
  'pad',
  'ph',
  'pid',
  'pipIcon',
  'playbackRateControls',
  'playbackRates',
  'playlist',
  'playlistIndex',
  'plugins',
  'preload',
  'qualityLabel',
  'qualityLabels',
  'recommendations',
  'related',
  'renderCaptionsNatively',
  'repeat',
  'safarihlsjs',
  'sdkplatform',
  'selectedBitrate',
  'setTimeEvents',
  'skin',
  'sharing',
  'sources',
  'stagevideo',
  'streamtype',
  'stretching',
  'title',
  'tracks',
  'type',
  'variations',
  'volume',
  'width',
  'withCredentials',
  'doNotTrack',
  'doNotTrackCookies',
  'images',
]);
