/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
import React, { useRef, FC, CSSProperties } from 'react';
import PresetSettings from './preset-settings';
import BorderSettings from './border-settings';
import BackgroundSettings from './background-settings';
import TextSettings from './text-settings';

type SubtitleSettings = {
  borderSettings: {
    color: string;
    effect: string;
  };
  backgroundSettings: {
    color: string;
    opacity: string;
  };
  textSettings: {
    color: string;
    opacity: string;
    font: string;
    size: string;
  };
};

interface SubtitleSettingsProps {
  containerStyle: CSSProperties;
  lineStyle: CSSProperties;
  subtitleSettings: SubtitleSettings;
  toggleSubtitleSettings: (
    isPlaying: boolean,
    setContainerStyle?: CSSProperties,
    setLineStyle?: CSSProperties,
  ) => void;
  setSubtitleSettings: ({
    borderSettings,
    backgroundSettings,
    textSettings,
  }: SubtitleSettings) => void;
  setSubtitleSettingsTab: (
    string,
  ) => void;
  subtitleSettingsTab: string;
  isPlaying: boolean;
}

const {
  i18n: {
    __,
  },
} = window.wp;

export const defaultSubtitleSettings = {
  presetSettings: 'default',
  borderSettings: {
    color: 'black',
    effect: 'None',
  },
  backgroundSettings: {
    color: 'black',
    opacity: '100%',
  },
  textSettings: {
    color: 'white',
    opacity: '100%',
    font: 'Lucida Sans',
    size: 'Medium',
  },
};

export const SubtitleSettings: FC<SubtitleSettingsProps> = ({
  containerStyle,
  lineStyle,
  toggleSubtitleSettings,
  setSubtitleSettings,
  setSubtitleSettingsTab,
  subtitleSettingsTab,
  subtitleSettings,
  isPlaying,
}) => {
  const {
    current: storedPlayState,
  } = useRef(isPlaying);

  const {
    borderSettings,
    backgroundSettings,
    textSettings,
  } = subtitleSettings;

  const setPresetSetting = (presetSettings) => {
    setSubtitleSettings(presetSettings);
  };

  const setBorderEffect = (effect) => {
    setSubtitleSettings({
      ...subtitleSettings,
      borderSettings: {
        ...borderSettings,
        effect,
      },
    });
  };

  const setBackgroundColor = (color) => {
    setSubtitleSettings({
      ...subtitleSettings,
      backgroundSettings: {
        ...backgroundSettings,
        color,
      },
    });
  };

  const setBackgroundOpacity = (opacity) => {
    setSubtitleSettings({
      ...subtitleSettings,
      backgroundSettings: {
        ...backgroundSettings,
        opacity,
      },
    });
  };

  const setTextColor = (color) => {
    setSubtitleSettings({
      ...subtitleSettings,
      textSettings: {
        ...textSettings,
        color,
      },
    });
  };

  const setTextOpacity = (opacity) => {
    setSubtitleSettings({
      ...subtitleSettings,
      textSettings: {
        ...textSettings,
        opacity,
      },
    });
  };

  const setTextFont = (font) => {
    setSubtitleSettings({
      ...subtitleSettings,
      textSettings: {
        ...textSettings,
        font,
      },
    });
  };

  const setTextSize = (size) => {
    setSubtitleSettings({
      ...subtitleSettings,
      textSettings: {
        ...textSettings,
        size,
      },
    });
  };

  const tabs = [
    {
      name: 'Presets',
      component: () => (
        <PresetSettings
          setPresetSetting={setPresetSetting}
        />
      ),
    }, {
      name: 'Text',
      component: () => (
        <TextSettings
          setTextColor={setTextColor}
          setTextOpacity={setTextOpacity}
          setTextFont={setTextFont}
          setTextSize={setTextSize}
          textSettings={textSettings}
        />
      ),
    }, {
      name: 'Border',
      component: () => (
        <BorderSettings
          setBorderEffect={setBorderEffect}
          borderSettings={borderSettings}
        />
      ),
    }, {
      name: 'Background',
      component: () => (
        <BackgroundSettings
          setBackgroundColor={setBackgroundColor}
          setBackgroundOpacity={setBackgroundOpacity}
          backgroundSettings={backgroundSettings}
        />
      ),
    },
  ];

  return (
    <div className="player__subtitle-settings">
      <div className="subtitle-settings__panel-top">
        {
          tabs.map(({ name }) => (
            <button
              type="button"
              className={`subtitle-settings__tab${(name === subtitleSettingsTab) ? ' subtitle-settings__tab--active' : ''}`}
              onClick={() => {
                setSubtitleSettingsTab(name);
              }}
            >
              {__(name, 'nbc')}
            </button>
          ))}
      </div>
      <div className="subtitle-settings__panel-middle">
        {
          tabs.map(
            ({ name, component }) => (
              name === subtitleSettingsTab &&
              component()
            )
          )
        }
      </div>
      <div className="subtitle-settings__panel-bottom">
        <div data-t-subtitles style={containerStyle} className="player__subtitles player__subtitles-sample">
          <div className="subtitles__line" style={lineStyle}>
            {__('This is a sample', 'nbc')}
          </div>
        </div>
        <div className="subtitle-settings__interface">
          <button
            type="button"
            className="subtitle-settings__button"
            onClick={() => {
              setSubtitleSettings({
                ...defaultSubtitleSettings,
              });
            }}
          >
            {__('Reset', 'nbc')}
          </button>
          <button
            type="button"
            className="subtitle-settings__button"
            onClick={() => {
              toggleSubtitleSettings(storedPlayState);
            }}
          >
            {__('Done', 'nbc')}
          </button>
        </div>
      </div>
    </div>
  );
};
