import React, { FC, useState, useEffect } from 'react';
import lscache from 'lscache';
import VideoPlayer from '../nbc-jw-player';
import EndCard from '../nbc-jw-player/components/end-card';
import {
  getUrlParam,
  isMobile,
  VideoProps,
  metaPropHandler,
  FastScheduleData,
} from '../nbc-jw-player/utils';

export type VideoPlaylistProps = {
  accountId: string;
  autoplay: boolean;
  islead?: boolean;
  brand: string;
  host: string;
  index?: number;
  twitter: string;
  callLetters: string;
  station: string;
  videos: VideoProps[];
  hubCallback?: () => void;
  continuousPlay?: string;
  excludeEndCard?: boolean;
  hideShare?: boolean;
  fastSchedule?: FastScheduleData[],
  placeholder?: boolean;
}

const VideoPlaylist: FC<VideoPlaylistProps> = ({
  accountId,
  autoplay,
  brand,
  host,
  index = 0,
  twitter,
  callLetters,
  station,
  videos,
  hubCallback,
  continuousPlay,
  placeholder = false,
  excludeEndCard = false,
  hideShare = false,
  islead = false,
  fastSchedule = [],
}) => {
  const [showVideo, setShowVideo] = useState(autoplay);
  const [currentIndex, setCurrentIndex] = useState(index);
  const [showEndCard, setShowEndCard] = useState(false);
  const [allVideos, setAllVideos] = useState(videos);
  const [isAutoplay, setIsAutoPlay] = useState(autoplay);
  const [isContinuousPlay, setIsContinuousPlay] = useState(continuousPlay);
  const [playerKey, setPlayerKey] = useState(index);

  const {
    title: currentVideoTitle,
    poster: currentVideoPoster,
  } = allVideos[currentIndex];
  const {
    title: nextVideoTitle,
    poster: nextVideoPoster,
  } = allVideos[currentIndex + 1] || {};

  const {
    nbc: {
      pageType = '',
    },
  } = window;

  const isEmbedded = 'true' === getUrlParam('embedded', '');

  const onClick = () => {
    setShowVideo(true);
  };

  useEffect(() => {
    if ('article' === pageType || isMobile) {
      setIsAutoPlay(true);
    }
  }, [showVideo]);

  useEffect(() => {
    // Only get related videos if a single video was passed.
    if (1 === allVideos.length && ! excludeEndCard && ! isEmbedded) {
      const getRelatedVideos = async () => {
        const cacheKey = 'relatedVideos';
        lscache.remove(cacheKey);
        let relatedVideos = lscache.get(cacheKey);

        if (! relatedVideos) {
          const response = await fetch('/wp-json/nbc/v1/template/videos');

          const {
            template_items: {
              items = [],
              video_archive: videoArchive = [],
            },
          } = await response.json();

          const videoCatalog = 0 < items.length ? items : videoArchive;

          if (0 === videoCatalog.length) {
            return;
          }

          relatedVideos = videoCatalog.reduce((video, item) => {
            const {
              video: {
                meta: {
                  mpx_is_livestream: isLivestream = '',
                  video_id: videoId = '',
                },
              },
            } = item;

            // Don't add any videos that are a live stream or are the same as the first video.
            if (videoId && '1' !== isLivestream && videoId !== videos[0].videoId) {
              video.push({
                ...metaPropHandler(item),
                autoplay: true,
              });
            }
            return video;
          }, []);

          lscache.set(cacheKey, relatedVideos, 60);
        }

        if (0 < relatedVideos.length) {
          setAllVideos([
            ...videos,
            ...relatedVideos,
          ]);
        }
      };

      getRelatedVideos();
    }

    if (2 === allVideos.length && 'full' === pageType) {
      setAllVideos([
        ...videos,
      ]);

      setIsContinuousPlay(continuousPlay);

      setShowEndCard(false);
    }
  }, [videos]);

  return (! showVideo && ('article' === pageType || isMobile)) ? (
    <div
      className="video-playlist__placeholder"
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
    >
      <img
        src={currentVideoPoster}
        alt={currentVideoTitle}
      />
      <span className="player__play-button--large" />
    </div>) :
    (
      <div className="video-playlist__container">
        <VideoPlayer
          key={playerKey}
          accountId={accountId}
          autoplay={isAutoplay}
          placeholder={placeholder}
          host={host}
          twitter={twitter}
          brand={brand}
          video={allVideos[currentIndex]}
          onSessionEnded={() => {
            if (currentIndex < allVideos.length && ! excludeEndCard && ! isEmbedded) {
              setShowEndCard(true);
            } else {
              setIsAutoPlay(false);
              setPlayerKey(index + 1);
              setCurrentIndex(0);
            }
          }}
          callLetters={callLetters}
          station={station}
          continuousPlay={isContinuousPlay}
          islead={islead}
          hideShare={hideShare}
          fastSchedule={fastSchedule}
        />
        {
          showEndCard && 'undefined' !== typeof allVideos[currentIndex + 1] &&
          <EndCard
            title={currentVideoTitle}
            poster={currentVideoPoster}
            nextVideoTitle={nextVideoTitle}
            nextVideoPoster={nextVideoPoster}
            onCountdownEnded={() => {
              if (currentIndex < allVideos.length) {
                setIsAutoPlay(true);
                setShowEndCard(false);
                setIsContinuousPlay('continuous');

                if (hubCallback && 'full' === pageType) {
                  hubCallback();
                } else {
                  setCurrentIndex(currentIndex + 1);
                }
              }
            }}
          />
        }
      </div>
    );
};

VideoPlaylist.defaultProps = {
  hubCallback: () => undefined,
  excludeEndCard: false,
  continuousPlay: 'first',
  fastSchedule: [],
  index: 0,
  hideShare: false,
  islead: false,
  placeholder: false,
};
export default VideoPlaylist;
