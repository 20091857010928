export const presetContainerDefault = {
  color: '#f2f2f2',
  font: '17px "Lucida Console", Monaco, monospace',
};

export const presetLineDefault = {
  backgroundColor: 'rgba(0, 0, 0, 1)',
};

export const textMap = {
  textColors: {
    white: '#FFFFFF',
    red: '#FF0000',
    green: '#008000',
    blue: '#0000FF',
    yellow: '#FFFF00',
    magenta: '#ff00ff',
    cyan: '#00ffff',
    black: '#000000',
    timesPreset: '#55c2b7',
  },
  textPreviewColors: {
    white: '255, 255, 255',
    red: '255, 0, 0',
    green: '0, 255, 0',
    blue: '0, 0, 255',
    yellow: '255, 255, 0',
    magenta: '255, 0, 255',
    cyan: '0, 255, 255',
    black: '0, 0, 0',
    timesPreset: '85, 194, 183',
  },
  textFonts: {
    'Lucida Sans': '"Lucida Console", Monaco, monospace',
    Verdana: 'Verdana',
    Helvetica: 'Helvetica',
    Arial: 'Arial',
    'Arial Black': 'Arial Black',
    Courier: 'Courier New',
    Georgia: 'Georgia',
    Impact: 'Impact',
    Palatino: 'Palatino',
    Tahoma: 'Tahoma',
    'Times New Roman': 'Times New Roman',
  },
  textSizes: {
    'Extra small': '0.5em',
    Small: '0.75em',
    Medium: '1em',
    Large: '1.5em',
    'Extra large': '2em',
  },
  textSizesMapping: {
    'Extra small': 'captionExtraSmall',
    Small: 'captionSmall',
    Medium: 'captionMedium',
    Large: 'captionLarge',
    'Extra large': 'captionExtraLarge',
  },
};

export const presetOptionsMap = {
  default: {
    borderSettings: {
      color: 'black',
      effect: 'None',
    },
    backgroundSettings: {
      color: 'black',
      opacity: '100%',
    },
    textSettings: {
      color: 'white',
      opacity: '100%',
      font: 'Lucida Sans',
      size: 'Medium',
    },
  },
  verdana: {
    borderSettings: {
      color: 'black',
      effect: 'None',
    },
    backgroundSettings: {
      color: 'white',
      opacity: '100%',
    },
    textSettings: {
      color: 'black',
      opacity: '100%',
      font: 'Verdana',
      size: 'Medium',
    },
  },
  courier: {
    borderSettings: {
      color: 'black',
      effect: 'None',
    },
    backgroundSettings: {
      color: 'black',
      opacity: '75%',
    },
    textSettings: {
      color: 'white',
      opacity: '100%',
      font: 'Courier',
      size: 'Medium',
    },
  },
  arial: {
    borderSettings: {
      color: 'black',
      effect: 'dropShadow',
    },
    backgroundSettings: {
      color: 'arialPreset',
      opacity: '100%',
    },
    textSettings: {
      color: 'white',
      opacity: '100%',
      font: 'Arial',
      size: 'Medium',
    },
  },
  times: {
    borderSettings: {
      color: 'black',
      effect: 'None',
    },
    backgroundSettings: {
      color: 'black',
      opacity: '88%',
    },
    textSettings: {
      color: 'timesPreset',
      opacity: '100%',
      font: 'Times New Roman',
      size: 'Medium',
    },
  },
  verdanaBlack: {
    borderSettings: {
      color: 'black',
      effect: 'dropShadow',
    },
    backgroundSettings: {
      color: 'black',
      opacity: '50%',
    },
    textSettings: {
      color: 'white',
      opacity: '100%',
      font: 'Verdana',
      size: 'Medium',
    },
  },
  timesBlack: {
    borderSettings: {
      color: 'black',
      effect: 'uniform',
    },
    backgroundSettings: {
      color: 'blue',
      opacity: '100%',
    },
    textSettings: {
      color: 'yellow',
      opacity: '100%',
      font: 'Times New Roman',
      size: 'Medium',
    },
  },
};

export const colorOptions = {
  white: {
    primary: 'rgb(255 255 255)',
    raisedPrimary: 'rgb(255 255 255)',
    secondary: 'rgb(127 127 127)',
  },
  red: {
    primary: 'rgb(255 0 0)',
    raisedPrimary: 'rgb(255 127 127)',
    secondary: 'rgb(127 0 0)',
  },
  green: {
    primary: 'rgb(0 255 0)',
    raisedPrimary: 'rgb(127 255 127)',
    secondary: 'rgb(0 127 0)',
  },
  blue: {
    primary: 'rgb(0 0 255)',
    raisedPrimary: 'rgb(127 127 255)',
    secondary: 'rgb(0 0 127)',
  },
  yellow: {
    primary: 'rgb(255 255 0)',
    raisedPrimary: 'rgb(255 255 127)',
    secondary: 'rgb(127 127 0)',
  },
  magenta: {
    primary: 'rgb(255 0 255)',
    raisedPrimary: 'rgb(255 127 255)',
    secondary: 'rgb(127 0 127)',
  },
  cyan: {
    primary: 'rgb(0 255 255)',
    raisedPrimary: 'rgb(127 255 255)',
    secondary: 'rgb(0 127 127)',
  },
  black: {
    primary: 'rgb(0 0 0)',
    raisedPrimary: 'rgb(127 127 127)',
    secondary: 'rgb(0 0 0)',
  },
};

export const effectMap = {
  None: {
    returnStyle: '',
    display: 'None',
  },
  dropShadow: {
    returnStyle: 'rgb(0, 0, 0) 0px 2px 1px',
    display: 'Drop Shadow',
  },
  depressed: {
    returnStyle: 'rgb(0, 0, 0) 0px -2px 1px',
    display: 'Depressed',
  },
  raised: {
    returnStyle: 'rgb(0, 0, 0) 0px 0px 5px, rgb(0, 0, 0) 0px 1px 5px, rgb(0, 0, 0) 0px 2px 5px',
    display: 'Raised',
  },
  uniform: {
    returnStyle: `rgb(0, 0, 0) -2px 0px 1px,
    rgb(0, 0, 0) 2px 0px 1px,
    rgb(0, 0, 0) 0px -2px 1px, rgb(0, 0, 0) 0px 2px 1px, rgb(0, 0, 0) -1px 1px 1px, rgb(0, 0, 0) 1px 1px 1px, rgb(0, 0, 0) 1px -1px 1px, rgb(0, 0, 0) 1px 1px 1px`,
    display: 'Uniform',
  },
};

export const backgroundColorMap = {
  white: '#FFFFFF',
  red: '#FF0000',
  green: '#008000',
  blue: '#0000FF',
  yellow: '#FFFF00',
  magenta: '#ff00ff',
  cyan: '#00ffff',
  black: '#000000',
  arialPreset: '#678ccc',
};

export const backgroundColorPreviewMap = {
  white: '255, 255, 255',
  red: '255, 0, 0',
  green: '0, 255, 0',
  blue: '0, 0, 255',
  yellow: '255, 255, 0',
  magenta: '255, 0, 255',
  cyan: '0, 255, 255',
  black: '0, 0, 0',
  arialPreset: '103, 140, 204',
};

export const opacityMap = {
  '100%': 100,
  '88%': 86,
  '75%': 75,
  '62%': 62,
  '50%': 50,
  '38%': 38,
  '25%': 25,
  '0%': 0,
};

export const opacityPreviewMap = {
  '100%': 1,
  '88%': 0.875,
  '75%': 0.75,
  '62%': 0.624,
  '50%': 0.5,
  '38%': 0.376,
  '25%': 0.25,
  '0%': 0,
};

export const captionFontSizeSettings = (settings) => {
  if (! settings) {
    return 'captionMedium';
  }

  const {
    textSettings: {
      size: textSize,
    },
  } = settings;

  const {
    textSizesMapping,
  } = textMap;

  return textSizesMapping[textSize];
};

export const containerSettings = (settings) => {
  if (! settings) {
    return presetContainerDefault;
  }

  const {
    textSettings: {
      color: textColor,
      opacity: fontOpacity,
      font: textFont,
      size: textSize,
    },
  } = settings;

  const {
    textPreviewColors,
    textFonts,
    textSizes,
  } = textMap;

  return {
    color: `rgba(${textPreviewColors[textColor]}, ${opacityPreviewMap[fontOpacity]})`,
    fontFamily: textFonts[textFont],
    fontSize: textSizes[textSize],
  };
};

export const lineSettings = (settings) => {
  if (! settings) {
    return presetLineDefault;
  }

  const {
    borderSettings: {
      effect: borderEffect,
    },
    backgroundSettings: {
      color: backgroundColor,
      opacity: backgroundOpacity,
    },
  } = settings;

  const {
    returnStyle,
  } = effectMap[borderEffect];

  return {
    textShadow: returnStyle,
    backgroundColor: `rgba(${backgroundColorPreviewMap[backgroundColor]}, ${opacityPreviewMap[backgroundOpacity]})`,
  };
};
