/* eslint-disable no-console */
import React, { FC } from 'react';
import { effectMap } from './subtitle-settings-utils';

interface BorderSettingsProps {
  setBorderEffect: (
    string
  ) => void;
  borderSettings: {
    color: string,
    effect: string,
  };
}

const {
  i18n: {
    __,
  },
} = window.wp;

const BorderSettings: FC<BorderSettingsProps> = ({
  setBorderEffect,
  borderSettings,
}) => {
  const {
    effect,
  } = borderSettings;

  return (
    <div className="border-settings">
      <div className="border-settings__effect">
        <span className="border-settings__label">{__('Effect', 'nbc')}</span>
        <select
          value={effect}
          defaultValue={effect}
          className="border-settings__select"
          onChange={(e) => {
            const {
              target: {
                value,
              },
            } = e;
            setBorderEffect(value);
          }}
        >
          {
            Object.entries(effectMap).map(([key, value]) => (
              <option
                value={key}
              >
                {__(value.display || key, 'nbc')}
              </option>
            ))
          }
        </select>
      </div>
    </div>
  );
};

export default BorderSettings;
