const {
  nbc: {
    dataLayer: {
      pageName,
    },
  },
} = window;

// #region Prop/evars we want to track when a link is clicked.
const linkTrackVars = [
  'prop1',
  'prop2',
  'prop3',
  'prop4',
  'prop8',
  'prop9',
  'prop10',
  'prop21',
  'prop22',
  'prop23',
  'prop48',
  'prop52',
  'prop53',
  'prop54',
  'prop55',
  'contextData.liveBlogCardId',
  'contextData.liveBlogCardTitle',
];

/**
 * #region Track a click/update event on a Live Blog component.
 *
 * @param {String} linkName Link name that was clicked.
 * @param {String} moduleName The module that was clicked.
 * @param {String} cardId (Optional) The ID of the card that was clicked.
 * @param {String} cardTitle (Optional) The title of the card that was clicked.
 */
export const trackClick = (linkName: string, moduleName: string, cardId?: string, cardTitle?: string) => {
  if ('undefined' === typeof window.s) { // sometimes s object isnt available, so we need to check with window.s
    return;
  }

  const {
    linkTrackVars: linkTrackVarsCopy,
    linkTrackEvents: linkTrackEventsCopy,
  } = window.s;

  // #region Declare which props/evars we want to track.
  window.s.linkTrackVars = linkTrackVars.join(',');
  window.s.linkTrackEvents = '';

  // #region Set props/evars.
  const trackVars = {
    prop52: pageName,
    prop53: linkName,
    prop54: `${pageName} | ${linkName}`,
    prop55: moduleName,
    contextData: {
      liveBlogCardId: '' !== cardId ? cardId : '',
      liveBlogCardTitle: '' !== cardTitle ? cardTitle : '',
    },
  };

  // #region Track the link.
  window.s.tl(this, 'o', moduleName, trackVars);

  // #region Reset linkTrackVars and linkTrackEvents.
  window.s.linkTrackVars = linkTrackVarsCopy;
  window.s.linkTrackEvents = linkTrackEventsCopy;
};

/**
 * #region Capture component tracking to pass to trackClick.
 *
 * @param {String} elementName Element name that was clicked.
 * @param {String} moduleName The module the element was clicked from.
 * @param {String} container The container the element was clicked from.
 */
export const liveBlogTracking = (elementName, moduleName, container) => {
  const containerElem = document.querySelector(`.${container}`);
  const cardId = containerElem?.closest('.liveblog-card')?.getAttribute('data-card-id') || '';
  const cardTitle = containerElem?.closest('.liveblog-card')?.getAttribute('data-card-title') || '';
  trackClick(elementName, moduleName, cardId, cardTitle);
};
