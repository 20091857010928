/* eslint-disable no-console */
import React, { FC } from 'react';
import { textMap, opacityMap } from './subtitle-settings-utils';

interface TextSettingsProps {
  setTextColor: (
    string,
  ) => void;
  setTextOpacity: (
    string
  ) => void;
  setTextFont: (
    string
  ) => void;
  setTextSize: (
    string
  ) => void;
  textSettings: {
    color: string,
    opacity: string,
    font: string,
    size: string,
  };
}

const {
  i18n: {
    __,
  },
} = window.wp;

const {
  textColors,
  textFonts,
  textSizes,
} = textMap;

const TextSettings: FC<TextSettingsProps> = ({
  setTextColor,
  setTextOpacity,
  setTextFont,
  setTextSize,
  textSettings,
}) => {
  const {
    color,
    font,
    size,
  } = textSettings;

  return (
    <div className="text-settings">
      <div className="text-settings__color">
        <span className="text-settings__label">{__('Color', 'nbc')}</span>
        {
          Object.keys(textColors).map((key) => (! ['timesPreset'].includes(key) ? (
            <button
              type="button"
              className={`text-settings__button${(key === color) ? ' text-settings__button--active' : ''}`}
              style={{
                backgroundColor: textColors[key],
              }}
              onClick={() => {
                setTextColor(key);
              }}
            >
              {key}
            </button>
          ) : ''))
        }
      </div>
      <div className="text-settings__opacity">
        <span className="text-settings__label">{__('Opacity', 'nbc')}</span>
        {
          Object.keys(opacityMap).map((key) => (
            <button
              type="button"
              className="text-settings__button"
              style={{
                backgroundColor: `rgba(255, 255, 255, ${opacityMap[key] / 100})`,
              }}
              onClick={() => {
                setTextOpacity(key);
              }}
            >
              {key}
            </button>
          ))
        }
      </div>
      <div className="text-settings__fonts">
        <span className="text-settings__label">{__('Font', 'nbc')}</span>
        <select
          value={font}
          defaultValue={font}
          className="text-settings__select"
          onChange={(e) => {
            const {
              target: {
                value,
              },
            } = e;
            setTextFont(value);
          }}
        >
          {
            Object.keys(textFonts).map((key) => (
              <option
                value={key}
              >
                {__(key, 'nbc')}
              </option>
            ))
          }
        </select>
        <span className="text-settings__label">{__('Size', 'nbc')}</span>
        <select
          value={size}
          defaultValue={size}
          className="text-settings__select"
          onChange={(e) => {
            const {
              target: {
                value,
              },
            } = e;
            setTextSize(value);
          }}
        >
          {
            Object.keys(textSizes).map((key) => (
              <option
                value={key}
              >
                {__(key, 'nbc')}
              </option>
            ))
          }
        </select>
      </div>
    </div>
  );
};

export default TextSettings;
