import React, { FC, useEffect } from 'react';

interface EndCardProps {
  title: string;
  poster: string;
  nextVideoTitle: string;
  nextVideoPoster: string;
  onCountdownEnded: () => void;
}

const {
  i18n: {
    __,
  },
} = window.wp;

let timerId;

const EndCard: FC<EndCardProps> = ({
  title,
  poster,
  nextVideoTitle,
  nextVideoPoster,
  onCountdownEnded,
}) => {
  useEffect(() => {
    timerId = setTimeout(onCountdownEnded, 5000);

    return () => clearTimeout(timerId);
  });

  return (
    <div className="player__end-card">
      <div className="end-card__panel">
        {__('Up Next', 'nbc')}
        <div className="end-card__container">
          <div
            className="end-card__image-container"
          >
            <div className="end-card__progress-bar">
              <span className="end-card__progress-container">
                <span
                  className="end-card__progress end-card__progress--running"
                />
              </span>
            </div>
            <img
              src={nextVideoPoster}
              alt={nextVideoTitle}
              className="end-card__image"
            />
            <button
              type="button"
              className="end-card__pause-button"
              onClick={() => {
                onCountdownEnded();
                clearTimeout(timerId);
              }}
            >
              Play
            </button>
          </div>
          <div
            className="end-card__text-container"
          >
            {nextVideoTitle}
          </div>
        </div>
      </div>
      {poster &&
        <img
          src={poster}
          className="player__poster"
          alt={title}
        />
      }
    </div>
  );
};

export default EndCard;
