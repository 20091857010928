/* eslint-disable no-console */
import React, { FC } from 'react';
import { backgroundColorMap, opacityMap } from './subtitle-settings-utils';

interface BackgroundSettingsProps {
  setBackgroundColor: (
    string,
  ) => void;
  setBackgroundOpacity: (
    string
  ) => void;
  backgroundSettings: {
    color: string,
  };
}

const {
  i18n: {
    __,
  },
} = window.wp;

const BackgroundSettings: FC<BackgroundSettingsProps> = ({
  setBackgroundColor,
  setBackgroundOpacity,
  backgroundSettings,
}) => {
  const {
    color,
  } = backgroundSettings;

  return (
    <div className="background-settings">
      <div className="background-settings__color">
        <span className="background-settings__label">{__('Color', 'nbc')}</span>
        {
          Object.keys(backgroundColorMap).map((key) => (! ['arialPreset'].includes(key) ? (
            <button
              type="button"
              className={`background-settings__button${(key === color) ? ' background-settings__button--active' : ''}`}
              style={{
                backgroundColor: backgroundColorMap[key],
              }}
              onClick={() => {
                setBackgroundColor(key);
              }}
            >
              {key}
            </button>
          ) : ''))
        }
      </div>
      <div className="background-settings__opacity">
        <span className="background-settings__label">{__('Opacity', 'nbc')}</span>
        {
          Object.keys(opacityMap).map((key) => (
            <button
              type="button"
              className="background-settings__button"
              style={{
                backgroundColor: `rgba(255, 255, 255, ${opacityMap[key] / 100})`,
              }}
              onClick={() => {
                setBackgroundOpacity(key);
              }}
            >
              {key}
            </button>
          ))
        }
      </div>
    </div>
  );
};

export default BackgroundSettings;
