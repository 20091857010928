import React from 'react';
import { render } from 'react-dom';
import domContentLoaded from 'utils/domContentLoaded';
import { checkForAnalytics } from 'components/nbc-jw-player/utils.ts';
import VideoPlaylist from '../../components/video-playlist';

import 'scss/jw-player/index.scss';

const containers = [...document.querySelectorAll('[data-react-component="VideoPlaylist"]')];

checkForAnalytics();

domContentLoaded(() => {
  document.addEventListener('sObjectLoaded', () => {
    containers.forEach((container) => {
      const props = JSON.parse(container.dataset.props);

      render(
        <VideoPlaylist {...props} />,
        container
      );
    });
  });
});
